<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 18:43:18
 * @LastEditors: 
 * @LastEditTime: 2024-01-27 02:19:23
-->
<template>
  <div class="part9 container-fluid" id="part9">
    <div class="part9-content1">
      <div>{{ txt.t1 }}</div>
      <div>{{ txt.t2 }}</div>
    </div>
    <div class="part9-content2">
      <div>
        <div v-for="item in  arr1" :key="item">{{ item }}</div>

      </div>
      <div>
        <div v-for="(item,idx) in  arr2" :key="item+idx">{{ item }}</div>
      </div>
    </div>
    <div class="part9-content3">{{ txt.t3 }}</div>
  </div>
</template>
  
<script>
export default {
  name: "Part-9",
  props: {
    language: {
      type: String,
      default: 'english'
    }
  },
  computed: {
    arr1() {
      if (this.language === 'english') {
        return ['Total numberof tokens', 'Token available', 'Token format', 'Nominal price', 'Emission rate', 'Private sale', 'Public Sale']
      }
      return [
        '代币总量',
        '流通代币',
        '代币网络',
        '名义价格',
        '发行速率',
        '私人售卖',
        '公开销售',
      ]
    },
    arr2() {
      if (this.language === 'english') {
        return ['2,000,000,000 $MWCC', '2,000,000,000 $MWCC', 'BSC Chain and TRC Chain', '0.004 USDT', 'No Further tokens will be created', '10%(Bonus up to 30%)', '20%(pre-sale)']
      }
      return ['2,000,000,000 $MWCC', '2,000,000,000 $MWCC', 'BSC Chain and TRC Chain', '0.004 USDT', '不会再新增代币供应量', '10%(Bonus up to 30%)', '10%(Bonus up to 30%)']
     
    },
    txt() {
      if (this.language === 'chinese') {
        return {
          t1: `代币经济学`,
          t2: `具体代币详情如下`,
          t3: `一般数量和营销数量中,除了外部Sale或Block deal的数量外，将全部销毁，以此实现代币通缩机制`,
        }
      }
      return {
        t1: `Token Economy`,
        t2: `The specific Token SPEC is as follows.`,
        t3: `All of the general and marketing volumes will be incinerated except for the quantity sold or block sold outside.implementing a token deflation mechanism with this.`,
      }
    }
  }
};
</script>
  
<style lang="less" scoped>
/* PC样式 */
@media (min-width: 1440px) {
  .part9 {
    height: 699px;
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &-content3 {
      width: 688px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.4000000059604645px;
      text-align: center;
      color: rgba(91, 90, 98, 1);
      margin-top: 20px;
    }

    &-content2 {
      box-shadow: 0px 10px 10px 0px rgba(255, 0, 0, 0.25);
      background: linear-gradient(0deg, rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.3)),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
      border: 2px solid rgba(255, 255, 255, 1);
      margin-top: -200px;
      width: 956px;
      height: 350px;
      border-radius: 34px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      >div:first-of-type {
        border-right: 1px solid #FFFFFF;
      }

      >div {
        flex: 1;
        line-height: 50px;
        text-align: center;

        >div {
          border-bottom: 1px solid #FFFFFF;
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:first-of-type {
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 18px;
          font-weight: 900;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:last-of-type {
          border-bottom: 0 none;
        }
      }
    }

    &-content1 {
      height: 428px;
      width: 428px;
      border-radius: 50%;
      background: linear-gradient(180deg, #EF7174 0%, rgba(255, 255, 255, 0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      >div:first-of-type {
        font-family: Inter;
        font-size: 50px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: -1px;
        text-align: left;
        margin-top: 90px;
        color: rgba(41, 41, 41, 1);
      }

      >div:last-of-type {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.4000000059604645px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
        margin-top: 20px;
      }
    }
  }
}

/* Pc样式  1240 1439以下 以上 */
@media (max-width: 1439px) and (min-width: 1240px) {
  .part9 {
    height: 699px;
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &-content3 {
      width: 688px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.4000000059604645px;
      text-align: center;
      color: rgba(91, 90, 98, 1);
      margin-top: 20px;
    }

    &-content2 {
      box-shadow: 0px 10px 10px 0px rgba(255, 0, 0, 0.25);
      background: linear-gradient(0deg, rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.3)),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
      border: 2px solid rgba(255, 255, 255, 1);
      margin-top: -200px;
      width: 956px;
      height: 350px;
      border-radius: 34px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      >div:first-of-type {
        border-right: 1px solid #FFFFFF;
      }

      >div {
        flex: 1;
        line-height: 50px;
        text-align: center;

        >div {
          border-bottom: 1px solid #FFFFFF;
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:first-of-type {
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 18px;
          font-weight: 900;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:last-of-type {
          border-bottom: 0 none;
        }
      }
    }


    &-content1 {
      height: 428px;
      width: 428px;
      border-radius: 50%;
      background: linear-gradient(180deg, #EF7174 0%, rgba(255, 255, 255, 0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      >div:first-of-type {
        font-family: Inter;
        font-size: 50px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: -1px;
        text-align: left;
        margin-top: 90px;
        color: rgba(41, 41, 41, 1);
      }

      >div:last-of-type {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.4000000059604645px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
        margin-top: 20px;
      }
    }
  }
}

/* Pc样式 960 1240以下 以上 */
@media (max-width: 1239px) and (min-width: 960px) {
  .part9 {
    position: relative;
    padding: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &-content3 {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.4000000059604645px;
      text-align: center;
      color: rgba(91, 90, 98, 1);
      margin-top: 20px;
      padding: 0 7.5px;
    }

    &-content2 {
      box-shadow: 0px 10px 10px 0px rgba(255, 0, 0, 0.25);
      background: linear-gradient(0deg, rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.3)),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
      border: 2px solid rgba(255, 255, 255, 1);
      margin-top: -120px;
      width: 956px;
      height: 350px;
      border-radius: 34px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      >div:first-of-type {
        border-right: 1px solid #FFFFFF;
      }

      >div {
        flex: 1;
        line-height: 50px;
        text-align: center;

        >div {
          border-bottom: 1px solid #FFFFFF;
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:first-of-type {
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 18px;
          font-weight: 900;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:last-of-type {
          border-bottom: 0 none;
        }
      }
    }

    &-content1 {
      height: 271px;
      width: 271px;
      border-radius: 50%;
      background: linear-gradient(180deg, #EF7174 0%, rgba(255, 255, 255, 0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      >div:first-of-type {
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: -1px;
        text-align: left;
        margin-top: 40px;
        color: rgba(41, 41, 41, 1);
      }

      >div:last-of-type {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.4000000059604645px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
        margin-top: 10px;
      }
    }
  }
}

/* Mobile样式 960 768以下 以上 */
@media (max-width: 959px) and (min-width: 768px) {
  .part9 {
    position: relative;
    padding: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &-content3 {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.4000000059604645px;
      text-align: center;
      color: rgba(91, 90, 98, 1);
      margin-top: 20px;
      padding: 0 7.5px;
    }
    &-content2 {
      box-shadow: 0px 10px 10px 0px rgba(255, 0, 0, 0.25);
      background: linear-gradient(0deg, rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.3)),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
      border: 2px solid rgba(255, 255, 255, 1);
      width: 90%;
      height: 249px;
      margin-top: -120px;
      border-radius: 34px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      >div:first-of-type {
        border-right: 1px solid #FFFFFF;
      }

      >div {
        flex: 1;
        line-height: 50px;
        text-align: center;

        >div {
          border-bottom: 1px solid #FFFFFF;
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:first-of-type {
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 18px;
          font-weight: 900;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:last-of-type {
          border-bottom: 0 none;
        }
      }
    }

    &-content1 {
      height: 271px;
      width: 271px;
      border-radius: 50%;
      background: linear-gradient(180deg, #EF7174 0%, rgba(255, 255, 255, 0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      >div:first-of-type {
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: -1px;
        text-align: left;
        margin-top: 40px;
        color: rgba(41, 41, 41, 1);
      }

      >div:last-of-type {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.4000000059604645px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .part9 {
    position: relative;
    padding: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &-content3 {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.4000000059604645px;
      text-align: center;
      color: rgba(91, 90, 98, 1);
      margin-top: 20px;
      padding: 0 7.5px;
    }
    
    &-content2 {
      box-shadow: 0px 10px 10px 0px rgba(255, 0, 0, 0.25);
      background: linear-gradient(0deg, rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.3)),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
      border: 2px solid rgba(255, 255, 255, 1);
      width: 95%;
      height: 249px;
      margin-top: -120px;
      border-radius: 34px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      >div:first-of-type {
        border-right: 1px solid #FFFFFF;
      }

      >div {
        flex: 1;
        line-height: 50px;
        text-align: center;

        >div {
          border-bottom: 1px solid #FFFFFF;
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:first-of-type {
          color: rgba(91, 90, 98, 1);
          font-family: Inter;
          font-size: 12px;
          font-weight: 900;
          letter-spacing: -0.4000000059604645px;
          text-align: center;
        }

        >div:last-of-type {
          border-bottom: 0 none;
        }
      }
    }

    &-content1 {
      height: 271px;
      width: 271px;
      border-radius: 50%;
      background: linear-gradient(180deg, #EF7174 0%, rgba(255, 255, 255, 0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      >div:first-of-type {
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: -1px;
        text-align: left;
        margin-top: 40px;
        color: rgba(41, 41, 41, 1);
      }

      >div:last-of-type {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.4000000059604645px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
        margin-top: 10px;
      }
    }
  }
}</style>
  