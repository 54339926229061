<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 08:40:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-26 08:53:31
-->
<template>
    <div id="shop">
        <img src="./assets/logo.png" />
        <div class="shop-btns">
            <div class="shop-btn1" @click="handleToshop">Korean version</div>
            <div class="shop-btn2">
                <div>English version</div>
                <div class="tip">English version coming soon</div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            language: 'english'
        }
    },
    methods:{
        handleToshop(){
            window.location.href = 'https://e-mec.me/User/Signin'
        }
       
    }
};
</script>
  
<style scoped>
html,
body {
    font-size: 10px;
}

#shop {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(125.92deg, #FFFFFF 29%, rgba(255, 106, 106, 0.415) 73.47%, rgba(255, 255, 255, 0) 105.03%);
}

.shop-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 80px;
}

.shop-btn1 {
    width: 186px;
    height: 68.47px;
    border-radius: 12px;
    cursor: pointer;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 68.47px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(229, 27, 32, 1);
    color: rgba(255, 250, 250, 1);
    margin-right: 80px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);

}

.shop-btn2 {
    width: 186px;
    height: 68.47px;
    border-radius: 12px;
    margin-left: 80px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 68.47px;
    letter-spacing: 0em;
    cursor: pointer;
    text-align: center;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);

    background: rgba(174, 174, 174, 1);
    color: rgba(255, 250, 250, 1);
    position: relative;
}

.tip {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.005em;
    color: rgba(103, 103, 103, 1);
    text-align: center;
    position: absolute;
    width: 286;
    left: 50%;
    transform: translateX(-50%);
}

/* Mobile样式 768以下 以上 */
@media (max-width: 767px) {
    img{
        width: 158px;
        height: 71px;
    }
    .shop-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 80px;
    }
    .shop-btn1 {
        margin-right: 0;
        height: 172.78px;
        height: 45.2px;
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 45.2px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 250, 250, 1);
    }

    .shop-btn2 {
        margin-left: 0;
        margin-top: 40px;
        height: 172.78px;
        height: 45.2px;
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 45.2px;
        letter-spacing: 0em;
        text-align: center;
        background: rgba(174, 174, 174, 1);
        color: rgba(255, 250, 250, 1);
    }

    .tip {
        font-size: 14px;
    }

}
</style>
  