<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 18:43:18
 * @LastEditors: 
 * @LastEditTime: 2024-01-27 02:19:23
-->
<template>
  <div class="part10 container-fluid" id="part10">
    <div class="part10-content1">
      <div>
        <img src="../assets/logo.png" alt="">
        <img @click="jumpTwitter" src="../assets/twitter.png" alt="">
        <img @click="jumpX" src="../assets/telegram.png" alt="">
      </div>
      <div>
        {{ txt.t1 }}
      </div>
    </div>
    <div class="address">All Rights Reserved @ METAWORLD</div>
  </div>
</template>
  
<script>
export default {
  name: "Part-10",
  props:{
    language: {
      type: String,
      default: 'english'
    }
  },
  methods: {
    jumpTwitter() {
      window.open('https://twitter.com/Metaworldcc', '_blank')
    },
    jumpX() {
      window.open('https://t.me/MetaworldccOfficia', '_blank')
    },
  },
  computed:{
    txt(){
      if(this.language === 'chinese'){
        return  {
          t1: `Virtual Nation Metaworld 加密货币区块链平台，卖家和买家共同成长，创建新的 Metaverse 购物生态系统。`,
        }
      }
      return {
        t1: `Virtual Nation metaworld Cryptocurrency Blockchain platform where Seller and buyer grow together and create a new
        metaverse shopping ecosystem.`,
      }
    }
  }
};
</script>
  
<style lang="less" scoped>
/* PC样式 */
@media (min-width: 1440px) {
  .part10 {
    position: relative;
    padding: 0;
    width: 1154px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >.address{
      margin: 20px 0;
    }

    &-content1 {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(236, 236, 236, 1);
      padding-bottom: 20px;
      >div:last-of-type{
        flex: 1;
        width: 463px;
      }
      >div:first-of-type{
        flex: 1;
        >img{
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 20px;
        }
        >img:first-of-type {
          display: block;
          width: 120px;
          height: 54.34px;
          margin-bottom: 20px;
        }
       
      }
    }
  }
}

/* Pc样式  1240 1439以下 以上 */
@media (max-width: 1439px) and (min-width: 1240px) {
  .part10 {
    position: relative;
    padding: 0;
    width: 1154px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >.address{
      margin: 20px 0;
    }

    &-content1 {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(236, 236, 236, 1);
      padding-bottom: 20px;
      >div:last-of-type{
        flex: 1;
        width: 463px;
      }
      >div:first-of-type{
        flex: 1;
        >img{
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 20px;
        }
        >img:first-of-type {
          display: block;
          width: 120px;
          height: 54.34px;
          margin-bottom: 20px;
        }
       
      }
    }
  }
}

    /* Pc样式 960 1240以下 以上 */
@media (max-width: 1239px) and (min-width: 960px) {
  .part10 {
    position: relative;
    padding: 0;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 21px;
    width: auto;
    >.address{
     
      font-size: 10px;
      text-align: center;
      margin: 0 auto;
      padding: 10px;
    }

    &-content1 {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(236, 236, 236, 1);
      padding-bottom: 20px;
     
      >div:last-of-type{
        width: 100%;
        padding: 0 30px;
        font-size: 14px;
        text-align: center;
      }
      >div:first-of-type{
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       justify-content: center;
       align-items: center;
       width: 186.25px;
       margin-bottom: 15px;
        >img{
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 20px;
        }
        >img:first-of-type {
          display: block;
          flex: 1;
          width: 186.25px;
          height: 84.34px;
          margin-bottom: 20px;
          margin-right: 0;
        }
       
      }
    }
  }
}
/* Mobile样式 960 768以下 以上 */
@media ( max-width: 960px ) and (min-width: 768px){
  .part10 {
    position: relative;
    padding: 0;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 21px;
    width: auto;
    >.address{
     
      font-size: 10px;
      text-align: center;
      margin: 0 auto;
      padding: 10px;
    }

    &-content1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(236, 236, 236, 1);
      padding-bottom: 20px;
     
      >div:last-of-type{
        width: 100%;
        padding: 0 30px;
        font-size: 14px;
        text-align: center;
      }
      >div:first-of-type{
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       justify-content: center;
       align-items: center;
       width: 186.25px;
       margin-bottom: 15px;
        >img{
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 20px;
        }
        >img:first-of-type {
          display: block;
          flex: 1;
          width: 186.25px;
          height: 84.34px;
          margin-bottom: 20px;
          margin-right: 0;
        }
       
      }
    }
  }
}
@media (max-width: 767px)  {
  .part10 {
    position: relative;
    padding: 0;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 21px;
    width: auto;
    >.address{
     
      font-size: 10px;
      text-align: center;
      margin: 0 auto;
      padding: 10px;
    }

    &-content1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(236, 236, 236, 1);
      padding-bottom: 20px;
     
      >div:last-of-type{
        width: 100%;
        padding: 0 30px;
        font-size: 14px;
        text-align: center;
      }
      >div:first-of-type{
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       justify-content: center;
       align-items: center;
       width: 186.25px;
       margin-bottom: 15px;
        >img{
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 20px;
        }
        >img:first-of-type {
          display: block;
          flex: 1;
          width: 186.25px;
          height: 84.34px;
          margin-bottom: 20px;
          margin-right: 0;
        }
       
      }
    }
  }
}
</style>
  