<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 18:43:18
 * @LastEditors: 
 * @LastEditTime: 2024-01-27 02:19:23
-->
<template>
  <div class="part6 container-fluid" id="part6">
    <img src="../assets/Group4.png" />
    <img src="../assets/Group5.png" />
    <img src="../assets/Group6.png" />
    <img src="../assets/Group7.png" />
    <img src="../assets/Group8.png" />
  </div>
</template>
  
<script>
export default {
  name: "Part-6",
  props:{
    language: {
      type: String,
      default: 'english'
    }
  },
};
</script>
  
<style lang="less" scoped>
.part6 > img {
  transition: transform 0.3s ease;
}
 
.part6 > img:hover {
  transform: scale(1.2);
}
/* PC样式 */
@media (min-width: 1440px) {
  .part6 {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    height: 602px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 188px;

    >img {
      width: 157.47px;
      height: 339px;
      cursor: pointer;
    }
  }
}


/* Pc样式  1240 1439以下 以上 */
@media (max-width: 1439px) and (min-width: 1240px) {
  .part6 {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    height: 602px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 188px;

    >img {
      width: 157.47px;
      height: 339px;
      cursor: pointer;
    }
  }
}

/* Pc样式 960 1240以下 以上 */
@media (max-width: 1239px) and (min-width: 960px) {
  .part6 {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    padding: 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 40px 30px 0 30px;
    flex-wrap: wrap;

    >img {
      width: 132.2px;
      height: 284.6px;
      cursor: pointer;
      margin-bottom: 25px;
    }
  }
}



/* Mobile样式 960 768以下 以上 */
@media (max-width: 960px) and (min-width: 768px) {
  .part6 {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    padding: 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 40px 30px 0 30px;
    flex-wrap: wrap;

    >img {
      width: 132.2px;
      height: 284.6px;
      cursor: pointer;
      margin-bottom: 25px;
    }
  }
}

@media (max-width: 767px) {
  .part6 {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    padding: 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 30px 0 30px;
    flex-wrap: wrap;

    >img {
      width: 132.2px;
      height: 284.6px;
      cursor: pointer;
      margin-bottom: 25px;
    }
  }
}</style>
  