<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 18:43:18
 * @LastEditors: 
 * @LastEditTime: 2024-01-27 02:19:23
-->
<template>
  <div class="part2 container-fluid" id="part2">
    <div class="part2-box">
      <transition appear name="animate__animated animate__bounce" enter-active-class="animate__slideInUp"
        leave-active-class="animate__bounceOutRight">
        <div class="part2-box-data slideInUp">
          <div class="item">
            <div>99.99%</div>
            <div>{{ txt.t1 }}</div>
          </div>
          <div class="item">
            <div>98.81%</div>
            <div>{{ txt.t2 }}</div>
          </div>
          <div class="item">
            <div>3,000+</div>
            <div>{{ txt.t3 }}</div>
          </div>
          <div class="item">
            <div>154,810+</div>
            <div>{{ txt.t4 }}</div>
          </div>
          <div class="item">
            <div>286,710+</div>
            <div>{{ txt.t5 }}</div>
          </div>
        </div>
      </transition>

      <div class="container Partnership">{{ txt.t6 }}</div>
      <div class="container partners">{{ txt.t7 }}</div>
      <transition appear name="animate__animated animate__bounce" enter-active-class="animate__fadeIn"
      leave-active-class="animate__bounceOutRight">
      <div class="img-box">
        <img src="../assets/lbank-11.png" />
        <img src="../assets/image133.png" />
        <img src="../assets/image134.png" />
        <img src="../assets/image136.png" />
        <img src="../assets/image135.png" />
        <img src="../assets/image137.png" />
        <img src="../assets/image138.png" />
        <img src="../assets/image139.png" />
        <img src="../assets/image140.png" />
        <img src="../assets/image141.png" />
        <img src="../assets/image142.png" />
        <img src="../assets/image143.png" />
        <img src="../assets/image144.png" />
        <img src="../assets/2024-01-30 21.55.34.jpg" />
        <img src="../assets/2024-01-30 21.55.43.jpg" />
        <img src="../assets/2024-01-30 21.55.50.jpg" />
      </div>
    </transition>
     
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Part-2",
  props:{
    language: {
      type: String,
      default: 'english'
    }
  },
  computed:{
    txt(){
      if(this.language === 'chinese'){
        return  {
          t1: 'MWCC 正常运行时间',
          t2: '购买成功率',
          t3: '卖家数量',
          t4: 'MWCC 消费者',
          t5: '商品销售量',
          t6: '合作伙伴',
          t7: '我们的客户/服务合作伙伴',
        }
      }
      return {
        t1: 'Historical MWCC uptime',
        t2: 'Purchase success rate',
        t3: 'Number of sellers',
        t4: 'MWCC Consumer',
        t5: 'Product sales',
        t6: 'Partnership',
        t7: 'OUR CLIENTS / Service partners',
      }
    }
  },
  mounted() {
        window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域

    },
    methods: {
        scrollHandle() {
            const offset = this.$el.getBoundingClientRect();
            const offsetTop = offset.top;
            const offsetBottom = offset.bottom;
            // const offsetHeight = offset.height;
            // 进入可视区域
            // console.log(offsetTop,offsetBottom)
            if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
                // console.log('进入可视区域');
                this.isShowAnimation = true
            } else {
                this.isShowAnimation = false
                // console.log('移出可视区域');

            }
        }

    }
};
</script>
  
<style lang="less" scoped>
/* PC样式 */
@media (min-width: 1440px) {
  .part2 {
    padding: 0;

    &-box {
      display: block;
      width: 1440px;
      padding: 70px;
      margin: 0 auto;

      .img-box {
        img {
          filter: grayscale(100%);
        }
        display: flex;
        width: 1074px;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 auto;
        margin-top: 30px;

        >img:nth-of-type(1) {
          width: 220px;
          height: 41px;
        }

        >img:nth-of-type(2) {
          width: 270px;
          height: 67px;
        }

        >img:nth-of-type(3) {
          width: 290px;
          height: 41px;
        }

        >img:nth-of-type(4) {
          width: 80px;
          height: 106px;
        }

        >img:nth-of-type(5) {
          width: 194px;
          height: 116px;
        }

        >img:nth-of-type(6) {
          width: 278px;
          height: 67px;
        }

        >img:nth-of-type(7) {
          width: 165px;
          height: 76px;
        }

        >img:nth-of-type(8) {
          width: 276px;
          height: 83px;
        }

        >img:nth-of-type(9) {
          width: 195px;
          height: 92px;
        }

        >img:nth-of-type(10) {
          width: 200px;
          height: 50px;
        }

        >img:nth-of-type(11) {
          width: 168px;
          height: 82px;
        }

        >img:nth-of-type(12) {
          width: 180px;
          height: 70px;
        }

        >img:nth-of-type(13) {
          width: 90px;
          height: 97px;
        }
        >img:nth-of-type(13) {
          width: 38.91px;
          height: 41.93px;
        }
        >img:nth-of-type(14) {
          width: 110px;
          height: 35px;
        }
        >img:nth-of-type(15) {
          width: 100px;
          height: 60px;
        }
        >img:nth-of-type(16) {
          width: 100px;
          height: 25px;
        }
      }

      .partners {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(46, 46, 46, 1);
        margin-top: 20px;
      }

      .Partnership {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: center;
        color: rgba(245, 71, 72, 1);
        margin-top: 80px;
      }

      &-data {
        display: grid;
        grid-template-columns: repeat(5, 20%);
        column-gap: 10px;

        >.item {
          background: rgba(244, 243, 247, 1);
          padding: 5px 10px;
          border-radius: 17px;

          >div:first-of-type {
            font-family: Poppins;
            font-size: 40px;
            font-weight: 900;
            letter-spacing: 0.01em;
            text-align: center;
          }

          >div:last-of-type {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 275;
            letter-spacing: 0.01em;
            text-align: center;

          }
        }
      }
    }
  }
}

/* Pc样式  1240 1439以下 以上 */
@media (max-width: 1439px) and (min-width: 1240px) {
  .part2 {
    padding: 0;

    &-box {
      display: block;
      padding: 70px;
      margin: 0 auto;

      .img-box {
        img {
          filter: grayscale(100%);
        }
        display: flex;
        width: 1074px;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 auto;
        margin-top: 30px;

        >img:nth-of-type(1) {
          width: 220px;
          height: 41px;
        }

        >img:nth-of-type(2) {
          width: 270px;
          height: 67px;
        }

        >img:nth-of-type(3) {
          width: 290px;
          height: 41px;
        }

        >img:nth-of-type(4) {
          width: 146px;
          height: 106px;
        }

        >img:nth-of-type(5) {
          width: 194px;
          height: 116px;
        }

        >img:nth-of-type(6) {
          width: 270px;
          height: 67px;
        }

        >img:nth-of-type(7) {
          width: 170px;
          height: 76px;
        }

        >img:nth-of-type(8) {
          width: 246px;
          height: 83px;
        }

        >img:nth-of-type(9) {
          width: 190px;
          height: 92px;
        }

        >img:nth-of-type(10) {
          width: 200px;
          height: 55px;
        }

        >img:nth-of-type(11) {
          width: 168px;
          height: 82px;
        }

        >img:nth-of-type(12) {
          width: 190px;
          height: 76px;
        }

        >img:nth-of-type(13) {
          width: 90px;
          height: 97px;
        }

        >img:nth-of-type(14) {
          width: 100px;
          height: 35px;
        }
        >img:nth-of-type(15) {
          width: 90px;
          height: 60px;
        }
        >img:nth-of-type(16) {
          width: 90px;
          height: 25px;
        }
      }

      .partners {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(46, 46, 46, 1);
        margin-top: 20px;
      }

      .Partnership {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: center;
        color: rgba(245, 71, 72, 1);
        margin-top: 80px;
      }

      &-data {
        display: grid;
        grid-template-columns: repeat(5, 20%);
        column-gap: 10px;

        >.item {
          background: rgba(244, 243, 247, 1);
          padding: 5px 10px;
          border-radius: 17px;

          >div:first-of-type {
            font-family: Poppins;
            font-size: 40px;
            font-weight: 900;
            letter-spacing: 0.01em;
            text-align: center;
          }

          >div:last-of-type {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 275;
            letter-spacing: 0.01em;
            text-align: center;

          }
        }
      }
    }
  }
}

/* Pc样式 960 1240以下 以上 */
@media (max-width: 1239px) and (min-width: 960px) {
  .part2 {
    padding: 0;

    &-box {
      display: block;
      width: 100%;
      padding: 20px 10px;
      margin: 0 auto;

      .img-box {
        img {
          filter: grayscale(100%);
        }
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 auto;
        margin-top: 30px;
        padding: 0 20px;

        >img:nth-of-type(1) {
          width: 220px;
          height: 41px;
        }

        >img:nth-of-type(2) {
          width: 270px;
          height: 67px;
        }

        >img:nth-of-type(3) {
          width: 290px;
          height: 41px;
        }

        >img:nth-of-type(4) {
          width: 146px;
          height: 106px;
        }

        >img:nth-of-type(5) {
          width: 204px;
          height: 116px;
        }

        >img:nth-of-type(6) {
          width: 278px;
          height: 67px;
        }

        >img:nth-of-type(7) {
          width: 177px;
          height: 76px;
        }

        >img:nth-of-type(8) {
          width: 276px;
          height: 83px;
        }

        >img:nth-of-type(9) {
          width: 205px;
          height: 92px;
        }

        >img:nth-of-type(10) {
          width: 248px;
          height: 55px;
        }

        >img:nth-of-type(11) {
          width: 168px;
          height: 82px;
        }

        >img:nth-of-type(12) {
          width: 194px;
          height: 76px;
        }

        >img:nth-of-type(13) {
          width: 90px;
          height: 97px;
        }
        >img:nth-of-type(14) {
          width: 110px;
          height: 35px;
        }
        >img:nth-of-type(15) {
          width: 100px;
          height: 60px;
        }
        >img:nth-of-type(16) {
          width: 100px;
          height: 25px;
        }
      }

      .partners {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 600;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(46, 46, 46, 1);
        margin-top: 10px;
      }

      .Partnership {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.01em;
        text-align: center;
        color: rgba(245, 71, 72, 1);
        margin-top: 20px;
      }

      &-data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        >.item {
          padding: 6px 0px;
          width: calc(41% - 10px);
          margin-right: 5px;
          background: rgba(244, 243, 247, 1);
          border-radius: 17px;
          display: inline-block;
          margin-bottom: 10px;

          >div:first-of-type {
            font-family: Poppins;
            font-size: 25px;
            font-weight: 900;
            letter-spacing: 0.01em;
            text-align: center;
          }

          >div:last-of-type {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 275;
            letter-spacing: 0.01em;
            text-align: center;

          }
        }

        >.item:first-of-type {
          display: block;
          width: 45%;
          margin: 0 auto;
          margin-bottom: 10px;
          margin-right: 50%;
          transform: translateX(50%);
        }
      }
    }
  }
}

/* Mobile样式 960 768以下 以上 */
@media (max-width: 959px) and (min-width: 768px) {
  .part2 {
    padding: 0;

    &-box {
      display: block;
      width: 100%;
      padding: 20px 10px;
      margin: 0 auto;

      .img-box {
        img {
          filter: grayscale(100%);
        }
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 10px;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-top: 30px;

        >img:nth-of-type(1) {
          width: 95px;
          height: 17.72px;
          margin-right: 10px;
        }

        >img:nth-of-type(2) {
          width: 100px;
          height: 38px;
        }

        >img:nth-of-type(3) {
          width: 90px;
          height: 21px;
        }

        >img:nth-of-type(4) {
          width: 65px;
          height: 45.82px;
        }

        >img:nth-of-type(5) {
          width: 88.59px;
          height: 50.15px;
        }

        >img:nth-of-type(6) {
          width: 120.18px;
          height: 28.96px;
        }

        >img:nth-of-type(7) {
          width: 76.51px;
          height: 32.85px;
        }

        >img:nth-of-type(8) {
          width: 119.6px;
          height: 35.88px;
        }

        >img:nth-of-type(9) {
          width: 88.92px;
          height: 39.77px;
        }

        >img:nth-of-type(10) {
          width: 107.21px;
          height: 23.78px;
        }

        >img:nth-of-type(11) {
          width: 72.62px;
          height: 35.45px;
        }

        >img:nth-of-type(12) {
          width: 83.80px;
          height: 32.85px;
        }

        >img:nth-of-type(13) {
          width: 38.91px;
          height: 41.93px;
        }
        >img:nth-of-type(14) {
          width: 38.91px;
          height: 41.93px;
        }
        >img:nth-of-type(15) {
          width: 38.91px;
          height: 41.93px;
        }
        >img:nth-of-type(16) {
          width: 38.91px;
          height: 41.93px;
        }
        >img:nth-of-type(14) {
          width: 110px;
          height: 35px;
        }
        >img:nth-of-type(15) {
          width: 100px;
          height: 60px;
        }
        >img:nth-of-type(16) {
          width: 100px;
          height: 25px;
        }
      }

      .partners {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 600;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(46, 46, 46, 1);
        margin-top: 10px;
      }

      .Partnership {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.01em;
        text-align: center;
        color: rgba(245, 71, 72, 1);
        margin-top: 20px;
      }

      &-data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        >.item {
          padding: 6px 0px;
          width: calc(41% - 10px);
          margin-right: 5px;
          background: rgba(244, 243, 247, 1);
          border-radius: 17px;
          display: inline-block;
          margin-bottom: 10px;

          >div:first-of-type {
            font-family: Poppins;
            font-size: 25px;
            font-weight: 900;
            letter-spacing: 0.01em;
            text-align: center;
          }

          >div:last-of-type {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 275;
            letter-spacing: 0.01em;
            text-align: center;

          }
        }

        >.item:first-of-type {
          display: block;
          width: 45%;
          margin: 0 auto;
          margin-bottom: 10px;
          margin-right: 50%;
          transform: translateX(50%);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .part2 {
    padding: 0;

    &-box {
      display: block;
      width: 100%;
      padding: 20px 10px;
      margin: 0 auto;

      .img-box {
        img {
          filter: grayscale(100%);
        }
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 10px;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-top: 30px;

        >img:nth-of-type(1) {
          width: 80px;
          height: 17.72px;
          margin-right: 10px;
        }

        >img:nth-of-type(2) {
          width: 60px;
          height: 25px;
        }

        >img:nth-of-type(3) {
          width: 90px;
          height: 21px;
        }

        >img:nth-of-type(4) {
          width: 55px;
          height: 45.82px;
        }

        >img:nth-of-type(5) {
          width: 88.59px;
          height: 50.15px;
        }

        >img:nth-of-type(6) {
          width: 90.18px;
          height: 23.96px;
        }

        >img:nth-of-type(7) {
          width: 76.51px;
          height: 32.85px;
        }

        >img:nth-of-type(8) {
          width: 100.6px;
          height: 25.88px;
        }

        >img:nth-of-type(9) {
          width: 88.92px;
          height: 39.77px;
        }

        >img:nth-of-type(10) {
          width: 90.21px;
          height: 23.78px;
        }

        >img:nth-of-type(11) {
          width: 72.62px;
          height: 35.45px;
        }

        >img:nth-of-type(12) {
          width: 83.80px;
          height: 32.85px;
        }

        >img:nth-of-type(13) {
          width: 38.91px;
          height: 41.93px;
        }
        >img:nth-of-type(14) {
          width: 80px;
          height: 25px;
        }
        >img:nth-of-type(15) {
          width: 55px;
          height: 25px;
        }
        >img:nth-of-type(16) {
          width: 50px;
          height: 15px;
        }
      }

      .partners {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(46, 46, 46, 1);
        margin-top: 10px;
      }

      .Partnership {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: center;
        color: rgba(245, 71, 72, 1);
        margin-top: 20px;
      }

      &-data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        >.item {
          padding: 6px 0px;
          width: calc(50% - 10px);
          margin-right: 5px;
          background: rgba(244, 243, 247, 1);
          border-radius: 17px;
          display: inline-block;
          margin-bottom: 10px;

          >div:first-of-type {
            font-family: Poppins;
            font-size: 25px;
            font-weight: 900;
            letter-spacing: 0.01em;
            text-align: center;
          }

          >div:last-of-type {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 275;
            letter-spacing: 0.01em;
            text-align: center;

          }
        }

        >.item:first-of-type {
          display: block;
          width: 60%;
          margin: 0 auto;
          margin-bottom: 10px;
        }
      }
    }
  }
}</style>
  