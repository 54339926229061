<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 18:43:18
 * @LastEditors: 
 * @LastEditTime: 2024-01-27 02:19:23
-->
<template>
  <div class="part4 container-fluid" id="part4">
    <div class="part4-content">
      <div class="part4-content-w1">{{txt.t1}}</div>
      <div class="part4-content-w2">{{ txt.t2 }}</div>
      <div class="part4-content-w3">{{ txt.t3 }}</div>
      <div class="part4-content-w4">
        <div class="part4-content-w4-item">
          <img src="../assets/Group3.png" class="part4-content-w4-item-logo" />
          <div class="part4-content-w4-item-w1">{{txt.t4}}</div>
          <div class="part4-content-w4-item-w2">{{txt.t5}}</div>
        </div>
        <div class="part4-content-w4-item">
          <img src="../assets/Group1.png" class="part4-content-w4-item-logo" />
          <div class="part4-content-w4-item-w1">{{txt.t6}}</div>
          <div class="part4-content-w4-item-w2">{{txt.t7}}</div>
        </div>
        <div class="part4-content-w4-item">
          <img src="../assets/Group2.png" class="part4-content-w4-item-logo" />
          <div class="part4-content-w4-item-w1">{{txt.t8}}</div>
          <div class="part4-content-w4-item-w2">{{txt.t9}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Part-4",
  props:{
    language: {
      type: String,
      default: 'english'
    }
  },
  computed:{
    txt(){
      if(this.language === 'chinese'){
        return  {
          t1: '与 元宇宙 购物服务一起进行的',
          t2: '顾客回馈服务',
          t3: '任何人都可以轻松操作，与顾客的沟通有保障的平台。就此而言，MWCC 是一个与顾客互动，共同创造元宇宙购物的价值的平台。',
          t4: '元宇宙市场',
          t5: '我们的目标是结合顾客的意见，将购物中心和元宇宙相结合，实现供应商API、全球运输和快速支付。',
          t6: '社交服务',
          t7: '允许粉丝在由会员运营的服务池中进行质押，建立共存的生态系统。',
          t8: '奖励系统',
          t9: '所有奖励将由卖家和买家共享，奖励结构透明，由区块链管理。',
        }
      }
      return {
        t1: 'Customer Reward Service with Metaverse',
        t2: 'Shopping Service',
        t3: `A platform that anyone can easily operate and ensure communication with customers. In
        addition, MWCC is a platform that creates a value ecosystem of metaverse shopping by breathing with customers.
      `,
        t4: 'Metaverse Market',
        t5: 'We aim to combine shopping malls and the metaverse with customer input, enabling supplier APIs, global shipping, and quick payments.',
        t6: 'Social Service',
        t7: 'Allowing fans to stake in the service pool operated by members establishes a coexisting ecosystem',
        t8: 'Multi Reward System',
        t9: 'All rewards will be shared between the seller and the buyer, with transparent reward structures managed by blockchain.'
      }
    }
  }
};
</script>
  
<style lang="less" scoped>
.part4-content-w4-item {
  transition: transform 0.3s ease;
}
 
.part4-content-w4-item:hover {
  transform: scale(1.2);
}
/* PC样式 */
@media (min-width: 1440px) {
  .part4 {
    display: block;
    overflow: hidden;
    padding: 0;

    &-content {
      width: 1061px;
      height: 599px;
      margin: 0 auto;
      margin-top: 134px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &-w1 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.17em;
        text-align: center;
        color: rgba(245, 115, 67, 1);
      }

      &-w2 {
        margin-top: 10px;
        font-family: Montserrat;
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -1px;
        text-align: center;
        color: rgba(41, 41, 41, 1);
      }

      &-w3 {
        margin-top: 10px;
        width: 616px;
        font-family: Inter;
        font-size: 17px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
      }

      &-w4 {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &-item {
          width: 261px;
          height: 303px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-items: flex-start;
          align-items: center;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(237, 237, 237, 1);

          &-logo {
            width: 74px;
            height: 74px;
          }

          &-w1 {
            width: 233.86px;
            font-family: Inter;
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: -0.5208333134651184px;
            text-align: center;
            color: rgba(41, 41, 41, 1);
          }

          &-w2 {
            font-family: Inter;
            font-size: 17px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.4000000059604645px;
            text-align: center;
            color: rgba(145, 144, 152, 1);

          }
        }
      }
    }
  }
}
/* Pc样式  1240 1439以下 以上 */
@media ( max-width: 1439px ) and (min-width: 1240px){
  .part4 {
    display: block;
    overflow: hidden;
    padding: 0;

    &-content {
      width: 1061px;
      height: 599px;
      margin: 0 auto;
      margin-top: 134px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &-w1 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.17em;
        text-align: center;
        color: rgba(245, 115, 67, 1);
      }

      &-w2 {
        margin-top: 10px;
        font-family: Montserrat;
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -1px;
        text-align: center;
        color: rgba(41, 41, 41, 1);
      }

      &-w3 {
        margin-top: 10px;
        width: 616px;
        font-family: Inter;
        font-size: 17px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
      }

      &-w4 {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &-item {
          width: 261px;
          height: 303px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-items: flex-start;
          align-items: center;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(237, 237, 237, 1);

          &-logo {
            width: 74px;
            height: 74px;
          }

          &-w1 {
            width: 233.86px;
            font-family: Inter;
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: -0.5208333134651184px;
            text-align: center;
            color: rgba(41, 41, 41, 1);
          }

          &-w2 {
            font-family: Inter;
            font-size: 17px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.4000000059604645px;
            text-align: center;
            color: rgba(145, 144, 152, 1);

          }
        }
      }
    }
  }
}

  /* Pc样式 960 1240以下 以上 */
@media ( max-width: 1239px ) and (min-width: 960px){
  .part4 {
    display: block;
    overflow: hidden;
    padding: 0;

    &-content {
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &-w1 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 22.5px;
        letter-spacing: 0.17em;
        text-align: center;
        color: rgba(245, 115, 67, 1);
      }

      &-w2 {
        margin-top: 10px;
        font-family: Montserrat;
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -1px;
        text-align: center;
        color: rgba(41, 41, 41, 1);
      }

      &-w3 {
        margin-top: 10px;
        width: 82%;
        font-family: Inter;
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
      }

      &-w4 {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        &-item {
          width: 30%;
          padding: 20px;
          display: flex;
          border-radius: 8px;
          margin-bottom: 20px;
          flex-direction: column;
          justify-items: flex-start;
          align-items: center;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(237, 237, 237, 1);

          &-logo {
            width: 74px;
            height: 74px;
          }

          &-w1 {
            width: 233.86px;
            font-family: Inter;
            font-size: 24px;
            font-weight: 600;
            line-height: 54px;
            letter-spacing: -0.5208333134651184px;
            text-align: center;
            color: rgba(41, 41, 41, 1);
          }

          &-w2 {
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.4000000059604645px;
            text-align: center;
            color: rgba(145, 144, 152, 1);

          }
        }
      }
    }
  }
}
/* Mobile样式 960 768以下 以上 */
@media ( max-width: 960px ) and (min-width: 768px){
  .part4 {
    display: block;
    overflow: hidden;
    padding: 0;

    &-content {
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &-w1 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 22.5px;
        letter-spacing: 0.17em;
        text-align: center;
        color: rgba(245, 115, 67, 1);
      }

      &-w2 {
        margin-top: 10px;
        font-family: Montserrat;
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -1px;
        text-align: center;
        color: rgba(41, 41, 41, 1);
      }

      &-w3 {
        margin-top: 10px;
        width: 82%;
        font-family: Inter;
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
      }

      &-w4 {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        &-item {
          width: 30%;
          padding: 20px;
          display: flex;
          border-radius: 8px;
          margin-bottom: 20px;
          flex-direction: column;
          justify-items: flex-start;
          align-items: center;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(237, 237, 237, 1);

          &-logo {
            width: 74px;
            height: 74px;
          }

          &-w1 {
            width: 233.86px;
            font-family: Inter;
            font-size: 24px;
            font-weight: 600;
            line-height: 54px;
            letter-spacing: -0.5208333134651184px;
            text-align: center;
            color: rgba(41, 41, 41, 1);
          }

          &-w2 {
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.4000000059604645px;
            text-align: center;
            color: rgba(145, 144, 152, 1);

          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .part4 {
    display: block;
    overflow: hidden;
    padding: 0;

    &-content {
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &-w1 {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 600;
        line-height: 22.5px;
        letter-spacing: 0.17em;
        text-align: center;
        color: rgba(245, 115, 67, 1);
      }

      &-w2 {
        margin-top: 10px;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -1px;
        text-align: center;
        color: rgba(41, 41, 41, 1);
      }

      &-w3 {
        margin-top: 10px;
        width: 82%;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: rgba(91, 90, 98, 1);
      }

      &-w4 {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &-item {
          width: 233.86px;
          height: 238px;
          padding: 20px;
          display: flex;
          border-radius: 8px;
          margin-bottom: 20px;
          flex: 1;
          flex-direction: column;
          justify-items: flex-start;
          align-items: center;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(237, 237, 237, 1);

          &-logo {
            width: 49px;
            height: 49px;
          }

          &-w1 {
            width: 233.86px;
            font-family: Inter;
            font-size: 17px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: -0.5208333134651184px;
            text-align: center;
            color: rgba(41, 41, 41, 1);
          }

          &-w2 {
            font-family: Inter;
            font-size: 13px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.4000000059604645px;
            text-align: center;
            color: rgba(145, 144, 152, 1);

          }
        }
      }
    }
  }
}
</style>
  