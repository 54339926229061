<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 08:40:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-26 08:53:31
-->
<template>
    <div id="home" ref="home">
        <Part1 :language="language" @changelan="handlechangelan" @entershop="entershop"/>
        <Part2 :language="language" />
        <Part3 :language="language" />
        <Part4 :language="language" />
        <Part5 :language="language" />
        <Part6 :language="language" />
        <Part7 :language="language" />
        <Part8 :language="language" />
        <Part9 :language="language" />
        <Part10 :language="language" />
    </div>
</template>
  
<script>

import Part1 from './components/Part1.vue'
import Part2 from './components/Part2.vue'
import Part3 from './components/Part3.vue'
import Part4 from './components/Part4.vue'
import Part5 from './components/Part5.vue'
import Part6 from './components/Part6.vue'
import Part7 from './components/Part7.vue'
import Part8 from './components/Part8.vue'
import Part9 from './components/Part9.vue'
import Part10 from './components/Part10.vue'
import VueScrollTo from 'vue-scrollto';
export default {
    components: {
        Part1,
        Part2,
        Part3,
        Part4,
        Part5,
        Part6,
        Part7,
        Part8,
        Part9,
        Part10
    },
    data() {
        return {
            language: 'english'
        }
    },
    methods: {
        entershop(){
            this.$emit('clickShop', true)
        },
        scrollToSection(sectionId) {
            VueScrollTo.scrollTo(`#${sectionId}`, 500);
        },
        handlechangelan(val) {
            this.language = val
            console.log('xxx',this.language)
        }
    }
};
</script>
  
<style>
html,
body {
    font-size: 10px;
}

#home {
    height: 100%;
    width: 100%;
}
</style>
  