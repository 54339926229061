<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 18:43:18
 * @LastEditors: 
 * @LastEditTime: 2024-01-27 02:19:23
-->
<template>
  <div class="part3 container-fluid" id="part3">
    <div class="part3-box">
      <img src="../assets/9433446.png" class="part3-box-img1" />
      <transition appear name="animate__animated animate__bounce" enter-active-class="animate__slideInLeft"
      leave-active-class="animate__bounceOutRight">
      <div class="part3-box-a">
        <div>{{ txt.t1 }}</div>
        <div>{{ txt.t2 }}</div>
        <div>{{ txt.t3 }}</div>
      </div>
      </transition>
     
      <img src="../assets/9433489.png" class="part3-box-img2" />
      <transition appear name="animate__animated animate__bounce" enter-active-class="animate__slideInRight"
      leave-active-class="animate__bounceOutRight">
      <div class="part3-box-b">
        <div>{{ txt.t4 }}</div>
        <div>{{ txt.t5 }}</div>
        <div>{{ txt.t6 }}</div>
      </div>
      </transition>


    </div>
  </div>
</template>
  
<script>
export default {
  name: "Part-3",
  props:{
    language: {
      type: String,
      default: 'english'
    }
  },
  computed:{
    txt(){
      if(this.language === 'chinese'){
        return  {
          t1: '首个全球 B2E（Buy2Earn）平台',
          t2: '在元宇宙中运营Web3专用商城',
          t3: '奖励为主的新的消费平台',
          t4: '全球分配网络',
          t5: '商品销售量',
          t6: '海关自动认证系统',
          t7: '奖励商场为消费者提供高额社区奖励',
        }
      }
      return {
        t1: 'Rewards-focused new consumer platform',
        t2: 'First Global B2E(Buy2Earn)Platform',
        t3: 'Web3 exclusive shopping mall operation in Metaverse',
        t4: 'Various shopping solutions',
        t5: 'Copyright Revenue from NFT Marketplace',
        t6: 'Seller-to-Customer Rewards with BuyToEarn',
      }
    }
  }
};
</script>
  
<style lang="less" scoped>
/* PC样式 */
@media (min-width: 1440px) {

  .part3 {
    background: rgba(3, 3, 4, 1);

    &-box {
      display: block;
      background: rgba(3, 3, 4, 1);
      height: 747px;
      width: 1440px;
      margin: 0 auto;
      position: relative;
      overflow: hidden;

      &-img1 {
        position: absolute;
        width: 691px;
        height: 460px;
        right: 10px;
        top: 40px;
      }

      &-img2 {
        position: absolute;
        width: 657px;
        height: 455px;
        left: -20px;
        bottom: -60px;
      }

      &-a {
        width: 764px;
        height: 186px;
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        background-color: #fff;
        font-size: 25px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 1);
        padding: 10px;
        position: absolute;
        left: 40px;
        top: 60px;
      }

      &-b {
        width: 764px;
        height: 186px;
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        background-color: #fff;
        font-size: 25px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0em;
        z-index: 2;
        text-align: left;
        color: rgba(0, 0, 0, 1);
        padding: 10px;
        position: absolute;
        right: 40px;
        bottom: 60px;
      }
    }
  }
}
/* Pc样式  1240 1439以下 以上 */
@media ( max-width: 1439px ) and (min-width: 1240px){

  .part3 {
    background: rgba(3, 3, 4, 1);

    &-box {
      display: block;
      background: rgba(3, 3, 4, 1);
      height: calc(51.8vw);
      margin: 0 auto;
      position: relative;
      overflow: hidden;

      &-img1 {
        position: absolute;
        width: calc(47.986vw);
        right: 10px;
        top: 40px;
      }

      &-img2 {
        position: absolute;
        width:calc(45.625vw);
        left: -20px;
        bottom: -60px;
      }

      &-a {
        width: calc(53.055vw);
        height: 186px;
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        background-color: #fff;
        font-size: 25px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 1);
        padding: 10px;
        position: absolute;
        left: 40px;
        top: 60px;
      }

      &-b {
        width: calc(53.055vw);
        height: 186px;
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        background-color: #fff;
        font-size: 25px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0em;
        z-index: 2;
        text-align: left;
        color: rgba(0, 0, 0, 1);
        padding: 10px;
        position: absolute;
        right: 40px;
        bottom: 60px;
      }
    }
  }
}

/* Pc样式 960 1240以下 以上 */
@media ( max-width: 1239px ) and (min-width: 960px){
  .part3 {
    background: rgba(3, 3, 4, 1);
    padding: 0;

    &-box {
      display: block;
      background: rgba(3, 3, 4, 1);
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      padding: 20px 20px 50px 20px;

      &-img1 {
        width: calc(53.953488vw);
        display: block;
        margin: 0 auto;
        margin-top: 40px;
      }

      &-img2 {
        width: calc(65.58vw);
        display: block;
        margin: 0 auto;
        margin-top: 20px;

      }

      &-a {
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        margin: 0 10px;
        background-color: #fff;
        font-size: 25px;
        font-weight: bold;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        padding: 10px 0px;
      }

      &-b {
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        margin: 0 10px;
        background-color: #fff;
        font-size: 25px;
        font-weight: bold;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        padding: 10px 0px;
      }
    }
  }
}

/* Mobile样式 960 768以下 以上 */
@media ( max-width: 960px ) and (min-width: 768px){
  .part3 {
    background: rgba(3, 3, 4, 1);
    padding: 0;

    &-box {
      display: block;
      background: rgba(3, 3, 4, 1);
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      padding: 20px 20px 50px 20px;

      &-img1 {
        width: calc(53.953488vw);
        display: block;
        margin: 0 auto;
        margin-top: 40px;
      }

      &-img2 {
        width: calc(65.58vw);
        display: block;
        margin: 0 auto;
        margin-top: 20px;

      }

      &-a {
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        margin: 0 10px;
        background-color: #fff;
        font-size: 25px;
        font-weight: bold;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        padding: 10px 0px;
      }

      &-b {
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        margin: 0 10px;
        background-color: #fff;
        font-size: 25px;
        font-weight: bold;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        padding: 10px 0px;
      }
    }
  }
}

@media (max-width: 767px) {

  .part3 {
    background: rgba(3, 3, 4, 1);
    padding: 0;

    &-box {
      display: block;
      background: rgba(3, 3, 4, 1);
      height: 667px;
      margin: 0 auto;
      position: relative;
      overflow: hidden;

      &-img1 {
        width: 232px;
        height: 155px;
        display: block;
        margin: 0 auto;
        margin-top: 40px;
      }

      &-img2 {
        width: 282px;
        height: 188px;
        display: block;
        margin: 0 auto;
        margin-top: 20px;

      }

      &-a {
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        margin: 0 10px;
        background-color: #fff;
        font-size: 14px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        padding: 10px 0px;
      }

      &-b {
        border-radius: 16px;
        font-family: Poppins;
        text-align: center;
        margin: 0 10px;
        background-color: #fff;
        font-size: 14px;
        font-weight: bold;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        padding: 10px 0px;
      }
    }
  }
}
</style>
  