<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 18:43:18
 * @LastEditors: 
 * @LastEditTime: 2024-01-27 02:19:23
-->
<template>
  <div class="part5 container-fluid" id="part5">
    <div class="part5-box">
      <div class="part5-box-content">
        <div>{{ txt.t1 }}</div>
        <div>{{ txt.t2 }}</div>
      </div>
      <transition appear name="animate__animated animate__bounce" leave-active-class="animate__bounceOutRight">
        <img src="../assets/Rectangle.png" class="sh" />
      </transition>
      <transition appear name="animate__animated animate__bounce">
        <img src="../assets/Rectangle1.png" class="sh" />
      </transition>
      <img src="../assets/Rectangle3.png" class="sh" />
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Part-5",
  props: {
    language: {
      type: String,
      default: 'english'
    }
  },
  computed: {
    txt() {
      if (this.language === 'chinese') {
        return {
          t1: '个人质押  多重奖励系统',
          t2: `SNS Integrated Management System活动"I am Brand"提供的最先进的社交邀请工具和技术， 只需在活动门户上点击“分享”，将好友邀请到URL链接，并根据好友参与活动或购买等贡献 度获得活动补偿，通过这种方式邀请好友的好友参与活动，从而创造自己的品牌，扩张网络， 创造可持续成长的收益基础，是集聚了病毒营销优势的简单、快速、强力的营销工具。`
        }
      }
      return {
        t1: `Personal Staking & Multi - Reward System`,
        t2: `SNS Integrated Management System
          Through a simple event, you can invite your friends to participate or purchase together, and you can receive
          rewards for your activities according to your contribution and role. Fast, powerful marketing tools that help
          you
          create your own brand and expand your network to sustain profitability and profitability.
       `
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域

  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;
      // const offsetHeight = offset.height;
      // 进入可视区域
      // console.log(offsetTop,offsetBottom)
      if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
        // console.log('进入可视区域');
        this.isShowAnimation = true
      } else {
        this.isShowAnimation = false
        // console.log('移出可视区域');

      }
    }

  }
};
</script>
  
<style lang="less" scoped>
@-webkit-keyframes shake {
  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-10deg);
  }

  30% {
    transform: rotate(5deg);
  }

  40% {
    transform: rotate(-5deg);
  }

  50%,
  100% {
    transform: rotate(0deg);
  }
}

.sh {
  animation: shake 1s 0.15s linear infinite;
  -moz-animation: shake 1s 0.15s linear infinite;
  /* Firefox */
  -webkit-animation: shake 1s 0.15s linear infinite;
  /* Safari and Chrome */
  -o-animation: shake 1s 0.15s linear infinite;
  /* Opera */
}

/* PC样式 */
@media (min-width: 1440px) {
  .part5 {
    background: rgba(254, 245, 245, 1);
    padding: 0;

    &-box {
      background: rgba(254, 245, 245, 1);
      height: 755px;
      width: 1440px;
      padding: 0;
      overflow: hidden;
      position: relative;
      margin: 0 auto;

      >img:first-of-type {
        position: absolute;
        width: 241px;
        height: 241px;
        top: 0;
        right: 40px;
        z-index: 1;
      }

      >img:nth-of-type(2) {
        position: absolute;
        left: 0;
        bottom: 120px;
        width: 248.1px;
        height: 248.1px;
      }

      >img:last-of-type {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 800px;
        height: 560px;
      }

      &-content {
        width: 446px;
        height: 370px;
        position: relative;
        margin-top: 136px;
        margin-left: 294px;

        >div:first-of-type {
          font-family: Poppins;
          font-size: 40px;
          width: 466px;
          font-weight: 700;
          line-height: 54px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(46, 46, 46, 1);
        }

        >div:last-of-type {
          margin-top: 24px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          width: 466px;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(103, 103, 103, 1);
        }
      }
    }
  }
}

/* Pc样式  1240 1439以下 以上 */
@media (max-width: 1439px) and (min-width: 1240px) {
  .part5 {
    background: rgba(254, 245, 245, 1);
    padding: 0;

    &-box {
      background: rgba(254, 245, 245, 1);
      height: 755px;
      padding: 0;
      overflow: hidden;
      position: relative;
      margin: 0 auto;

      >img:first-of-type {
        position: absolute;
        width: calc(16.73611vw);
        height: calc(16.73611vw);
        top: 0;
        right: 40px;
        z-index: 1;
      }

      >img:nth-of-type(2) {
        position: absolute;
        left: 0;
        bottom: 120px;
        width: calc(17.22222vw);
        height: calc(17.22222vw);
      }

      >img:last-of-type {
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(55.5555vw);
        height: calc(38.8888vw);
      }

      &-content {
        width: 446px;
        height: 370px;
        position: relative;
        margin-top: 136px;
        margin-left: 294px;

        >div:first-of-type {
          font-family: Poppins;
          font-size: 40px;
          width: 466px;
          font-weight: 700;
          line-height: 54px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(46, 46, 46, 1);
        }

        >div:last-of-type {
          margin-top: 24px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          width: 466px;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(103, 103, 103, 1);
        }
      }
    }
  }
}

/* Pc样式 960 1240以下 以上 */
@media (max-width: 1239px) and (min-width: 960px) {
  .part5 {
    background: rgba(254, 245, 245, 1);
    padding: 0;

    &-box {
      background: rgba(254, 245, 245, 1);
      height: 755px;
      padding: 0;
      overflow: hidden;
      position: relative;
      margin: 0 auto;

      >img:first-of-type {
        position: absolute;
        width: calc(28.9vw);
        height: calc(28.9vw);
        top: 0;
        right: 0;
        z-index: 1;
      }

      >img:nth-of-type(2) {
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(28.9vw);
        height: calc(28.9vw);
        z-index: 1;
      }

      >img:last-of-type {
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(50vw);
        height: calc(45vw);
      }

      &-content {
        width: 446px;
        height: 370px;
        position: relative;
        margin-top: calc(10vw);
        margin-left: calc(20vw);
        z-index: 2;

        >div:first-of-type {
          font-family: Poppins;
          font-size: 40px;
          width: 466px;
          font-weight: 700;
          line-height: 54px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(46, 46, 46, 1);
        }

        >div:last-of-type {
          margin-top: 24px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          width: 466px;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(103, 103, 103, 1);
        }
      }
    }
  }
}

/* Mobile样式 959 768以下 以上 */
@media (max-width: 959px) and (min-width: 768px) {
  .part5 {
    background: rgba(254, 245, 245, 1);
    padding: 0;

    &-box {
      background: rgba(254, 245, 245, 1);
      height: 755px;
      padding: 0;
      overflow: hidden;
      position: relative;
      margin: 0 auto;

      >img:first-of-type {
        position: absolute;
        width: calc(28.9vw);
        height: calc(28.9vw);
        top: 0;
        right: 0;
        z-index: 1;
      }

      >img:nth-of-type(2) {
        position: absolute;
        left: 0;
        top: calc(50vw);
        width: calc(28.9vw);
        height: calc(28.9vw);
        z-index: 1;
      }

      >img:last-of-type {
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(50vw);
        height: calc(45vw);
      }

      &-content {
        width: 446px;
        height: 370px;
        position: relative;
        margin-top: calc(10vw);
        margin-left: calc(20vw);
        z-index: 2;

        >div:first-of-type {
          font-family: Poppins;
          font-size: 40px;
          width: 466px;
          font-weight: 700;
          line-height: 54px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(46, 46, 46, 1);
        }

        >div:last-of-type {
          margin-top: 24px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          width: 466px;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(103, 103, 103, 1);
        }
      }
    }
  }
}


@media (max-width: 767px) {
  .part5 {
    background: rgba(254, 245, 245, 1);
    padding: 0;

    &-box {
      background: rgba(254, 245, 245, 1);
      height: 583px;
      padding: 0;
      overflow: hidden;
      position: relative;
      margin: 0 auto;

      >img:nth-of-type(1) {
        position: absolute;
        display: block;
        width: 125px;
        height: 125px;
        top: 30px;
        right: 5px;
        z-index: 1;
      }

      >img:nth-of-type(2) {
        position: absolute;
        left: 0;
        bottom: 120px;
        width: 124.31px;
        height: 124.31px;
      }

      >img:nth-of-type(3) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 90%;
        height: 279px;
      }

      &-content {
        width: 83.4%;
        height: 370px;
        position: relative;
        margin: 0 auto;
        margin-top: 80px;

        >div:first-of-type {
          font-family: Poppins;
          width: 242px;
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(46, 46, 46, 1);
        }

        >div:last-of-type {
          margin-top: 10px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(103, 103, 103, 1);
        }
      }
    }
  }
}
</style>
  