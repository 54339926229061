<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 18:43:18
 * @LastEditors: 
 * @LastEditTime: 2024-01-27 02:19:23
-->
<template>
  <div class="part7 container-fluid" id="part7">
    <div class="part7-content1">
      <div class="imgbox">
        <img src="../assets/man6.png" />
        <div class="graybg"></div>
      </div>
      <div class="part7-content1-w1">
        <div class="part7-content1-w1-line1">{{ txt.t1 }}</div>
        <div class="part7-content1-w1-line2">{{ txt.t2 }}</div>
        <ul class="part7-content1-w1-line3">
          <li><span></span><span>{{ txt.t3 }}</span></li>
          <li><span></span><span>{{ txt.t4 }}</span></li>
          <li><span></span><span>{{ txt.t5 }}</span></li>
          <li><span></span><span>{{ txt.t6 }}</span></li>
        </ul>
      </div>
    </div>
    <div class="part7-content2">
      <div class="part7-content2-item">
        <img src="../assets/Group12.png"/>
        <div>{{ txt.t7 }}</div>
      </div>
      <div class="part7-content2-item">
        <img src="../assets/Group9.png"/>
        <div>{{ txt.t8 }}</div>
      </div>
      <div class="part7-content2-item">
        <img src="../assets/Group11.png"/>
        <div>{{ txt.t9 }}</div>
      </div>
      <div class="part7-content2-item">
        <img src="../assets/Group10.png"/>
        <div>{{ txt.t10 }}</div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Part-7",
  props:{
    language: {
      type: String,
      default: 'english'
    }
  },
  computed:{
    txt(){
      if(this.language === 'chinese'){
        return  {
          t1: `MWCC NFT`,
          t2: 'MWCC NFT的独创性',
          t3: '有故事的 NFT : 即使是一张照片，也可赋予故事，吸引顾客的关注。',
          t4: '想要拥有的收藏品 : 不是一张张照片的罗列，而是构建了可以收集并拥有照片集的服务。',
          t5: '高价值形成 : 价格是由市场结构决定的，但在商品化过程可以帮助形成额外的价格。',
          t6: '可靠的收益分配 : 由于区块链的性质，无法操纵交易细节或费用。 它根据最初确定的智 能合约可靠地运行。',
          t7: '高价值',
          t8: '透明的收益分配',
          t9: '社交分享',
          t10: '收藏品'
        }
      }
      return {
        t1: `MWCC NFT`,
        t2: `Originality of MWCC NFT`,
        t3: `NFT with a story : It gives you high storytelling with a single photo, which attracts
              customers' attention.`,
        t4: `Collection you want to own : We built a service that allows you to collect and own
              photos, not to list them one by one.`,
        t5: `High Value Formation : The formation of prices is made in the market structure. But in
              terms of commercialization, it can help create additional prices
            `,
        t6: `Reliable revenue allocation : Due to the nature of the blockchain, transaction details or
              fees cannot be manipulated. Works reliably according to the initial Smart Contract.`,
        t7: 'High Value',
        t8: 'Commerce Service',
        t9: 'Share social',
        t10: 'Collection'
      }
    }
  }
};
</script>
  
<style lang="less" scoped>
.graybg{
  position:absolute;
  width: 82.6%;
  height: 82.6%;
  border-radius: 50%;

  background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* PC样式 */
@media (min-width: 1440px) {
  .part7 {
    height: 883px;
    position: relative;
    padding: 0;

    &-content1 {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &-w1 {
        width: 684px;
        height: 445px;

        &-line1 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.17em;
          text-align: left;
          color: rgba(255, 178, 0, 1);
        }

        &-line2 {
          font-family: Inter;
          font-size: 50px;
          font-weight: 700;
          line-height: 50px;
          letter-spacing: -1px;
          text-align: left;
          color: rgba(41, 41, 41, 1);
        }

        &-line3 {
          padding-left: 8px;
          margin-top: 10px;
          padding-top: 10px;
          >li {
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            margin-bottom: 30px;
            >span:first-of-type{
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-right: 4px;
              line-height: 30px;
              border-radius: 16px;
              background: rgba(217, 217, 217, 1);
            }
            >span:last-of-type {
              flex: 1;
              font-family: Inter;
              display: inline-block;
              font-size: 18px;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: -0.4000000059604645px;
              text-align: left;
              color: rgba(91, 90, 98, 1);

            }
          }
        }
      }

      >.imgbox{
        position: relative;
        >img {
          height: 518px;
          position: relative;
          z-index: 2;
          width: 518px;
        }
      }
      
    }
    &-content2 {
      width: 1120px;
      height: 195px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 195px;
        border: 1px solid rgba(237, 237, 237, 1);
        border-radius: 8px;
        >img {
          width: 74px;
          height: 74px;
        }
        >div{
          margin-top: 20px;
          font-family: Inter;
          font-size: 24px;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: -0.5208333134651184px;
          text-align: center;
          color: rgba(41, 41, 41, 1);
        }
      }
    }
  }
}
/* Pc样式  1240 1439以下 以上 */
@media (max-width: 1439px) and (min-width: 1240px) {
  .part7 {
    height: 883px;
    position: relative;
    padding: 0;

    &-content1 {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 100px;

      &-w1 {
        width: 684px;
        height: 445px;

        &-line1 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.17em;
          text-align: left;
          color: rgba(255, 178, 0, 1);
        }

        &-line2 {
          font-family: Inter;
          font-size: 50px;
          font-weight: 700;
          line-height: 50px;
          letter-spacing: -1px;
          text-align: left;
          color: rgba(41, 41, 41, 1);
        }

        &-line3 {
          padding-left: 8px;
          margin-top: 10px;
          padding-top: 10px;
          >li {
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            margin-bottom: 30px;
            >span:first-of-type{
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-right: 4px;
              line-height: 30px;
              border-radius: 16px;
              background: rgba(217, 217, 217, 1);
            }
            >span:last-of-type {
              flex: 1;
              font-family: Inter;
              display: inline-block;
              font-size: 18px;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: -0.4000000059604645px;
              text-align: left;
              color: rgba(91, 90, 98, 1);

            }
          }
        }
      }
      >.imgbox{
        position: relative;
        >img {
          height: 518px;
          position: relative;
          z-index: 2;
          width: 518px;
        }
      }
     
    }
    &-content2 {
      width: 1120px;
      height: 195px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 195px;
        border: 1px solid rgba(237, 237, 237, 1);
        border-radius: 8px;
        >img {
          width: 74px;
          height: 74px;
        }
        >div{
          margin-top: 20px;
          font-family: Inter;
          font-size: 24px;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: -0.5208333134651184px;
          text-align: center;
          color: rgba(41, 41, 41, 1);
        }
      }
    }
  }
}

  /* Pc样式 960 1240以下 以上 */
@media (max-width: 1239px) and (min-width: 960px) {
  .part7 {
    position: relative;
    padding: 0;

    &-content1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      &-w1 {
        width: 84%;
        padding: 20px;
        &-line1 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.17em;
          text-align: left;
          color: rgba(255, 178, 0, 1);
        }

        &-line2 {
          font-family: Inter;
          font-size: 50px;
          font-weight: 700;
          line-height: 50px;
          letter-spacing: -1px;
          text-align: left;
          color: rgba(41, 41, 41, 1);
        }

        &-line3 {
          padding-left: 0px;
          margin-top: 5px;
          >li {
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            margin-bottom: 10px;
            >span:first-of-type{
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-right: 4px;
              line-height: 30px;
              border-radius: 16px;
              background: rgba(217, 217, 217, 1);
            }
            >span:last-of-type {
              flex: 1;
              font-family: Inter;
              display: inline-block;
              font-size: 18px;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: -0.4000000059604645px;
              text-align: left;
              color: rgba(91, 90, 98, 1);

            }
          }
        }
      }

      >.imgbox{
        position: relative;
        >img {
          width: calc(36vw);
          position: relative;
          z-index: 2;
          padding: 20px;
        }
      }
      
    }


    &-content2 {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 30px;
      align-items: center;
      align-content: flex-start;
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        width: 148px;
        height: 123px;
        border: 1px solid rgba(237, 237, 237, 1);
        border-radius: 8px;
        >img {
          width: 43.81px;
          height: 43.81px;
        }
        >div{
          margin-top: 20px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: -0.5208333134651184px;
          text-align: center;
          color: rgba(41, 41, 41, 1);
        }
      }
    }
  }
}

/* Mobile样式 960 768以下 以上 */
@media ( max-width: 960px ) and (min-width: 768px){
  .part7 {
    position: relative;
    padding: 0;

    &-content1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      &-w1 {
        width: 84%;
        &-line1 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.17em;
          text-align: left;
          color: rgba(255, 178, 0, 1);
        }

        &-line2 {
          font-family: Inter;
          font-size: 50px;
          font-weight: 700;
          line-height: 50px;
          letter-spacing: -1px;
          text-align: left;
          color: rgba(41, 41, 41, 1);
        }

        &-line3 {
          padding-left: 0px;
          margin-top: 5px;
          >li {
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            margin-bottom: 10px;
            >span:first-of-type{
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-right: 4px;
              line-height: 30px;
              border-radius: 16px;
              background: rgba(217, 217, 217, 1);
            }
            >span:last-of-type {
              flex: 1;
              font-family: Inter;
              display: inline-block;
              font-size: 18px;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: -0.4000000059604645px;
              text-align: left;
              color: rgba(91, 90, 98, 1);

            }
          }
        }
      }

      >.imgbox{
        position: relative;
        >img {
          width: calc(76vw);
          position: relative;
          z-index: 2;
        }
      }
     
    }


    &-content2 {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 30px;
      align-items: center;
      align-content: flex-start;
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        width: 148px;
        height: 123px;
        border: 1px solid rgba(237, 237, 237, 1);
        border-radius: 8px;
        >img {
          width: 43.81px;
          height: 43.81px;
        }
        >div{
          margin-top: 20px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: -0.5208333134651184px;
          text-align: center;
          color: rgba(41, 41, 41, 1);
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .part7 {
    position: relative;
    padding: 0;

    &-content1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      &-w1 {
        width: 84%;
        &-line1 {
          font-family: Poppins;
          font-size: 15px;
          font-weight: 600;
          line-height: 22.5px;
          letter-spacing: 0.17em;
          text-align: left;
          color: rgba(255, 178, 0, 1);
        }

        &-line2 {
          font-family: Inter;
          font-size: 20px;
          font-weight: 700;
          line-height: 50px;
          letter-spacing: -1px;
          text-align: left;
          color: rgba(41, 41, 41, 1);
        }

        &-line3 {
          padding-left: 0px;
          margin-top: 5px;
          >li {
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: top;
            margin-bottom: 10px;
            >span:first-of-type{
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-right: 4px;
              line-height: 20px;
              border-radius: 16px;
              background: rgba(217, 217, 217, 1);
            }
            >span:last-of-type {
              flex: 1;
              font-family: Inter;
              display: inline-block;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.4000000059604645px;
              text-align: left;
              color: rgba(91, 90, 98, 1);

            }
          }
        }
      }

      >.imgbox{
        position: relative;
        >img {
          width: calc(76.046vw);
          height: calc(76.046vw);
          position: relative;
          z-index: 2;
        }
      }
      
    }


    &-content2 {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 30px;
      align-items: center;
      align-content: flex-start;
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        width: 148px;
        height: 123px;
        border: 1px solid rgba(237, 237, 237, 1);
        border-radius: 8px;
        >img {
          width: 43.81px;
          height: 43.81px;
        }
        >div{
          margin-top: 20px;
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: -0.5208333134651184px;
          text-align: center;
          color: rgba(41, 41, 41, 1);
        }
      }
    }
  }
}
</style>
  