<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 18:43:18
 * @LastEditors: 
 * @LastEditTime: 2024-01-27 02:19:23
-->
<template>
  <div class="part8 container-fluid" id="part8">
    <video   src="http://mwcurrency.com/video/video.MP4" autoplay muted>
    </video>
  </div>
  <!-- <div class="part8 container-fluid" id="part8">
    <div>MWCC ROADMAP</div>
    <div class="inner-line">
      <img src="../assets/Ellipse.png" />
      <img src="../assets/Line123.png" />
      <div class="item item1">
        <div>2022. 12</div>
        <ul>
          <li><span class="dot"></span>Token Build</li>
          <li><span class="dot"></span>Private Sales</li>
        </ul>
      </div>
      <div class="item item2">
        <div>2023. 01</div>
        <ul>
          <li><span class="dot"></span>Launch PR Campaign for MWCC</li>
          <li><span class="dot"></span>Launch MWCC Wallet</li>
        </ul>
      </div>
      <div class="item item3">
        <div>2023. 03</div>
        <ul>
          <li><span class="dot"></span>1st Exchange Listing</li>
        </ul>
      </div>
      <div class="item item4">
        <div>2023. 06</div>
        <ul>
          <li><span class="dot"></span>Launch PR Campaign for MWCC</li>
          <li><span class="dot"></span>Launch MWCC Wallet</li>
        </ul>
      </div>
      <div class="item item5">
        <div>2023. 11</div>
        <ul>
          <li><span class="dot"></span>Influencer shop Open</li>
          <li><span class="dot"></span>MWCCshop.com Open</li>
        </ul>
      </div>
      <div class="item item6">
        <div>2024. 04</div>
        <ul>
          <li><span class="dot"></span>2nd Exchange Listing</li>
          <li><span class="dot"></span>MWCC Staking Service Open</li>
          <li><span class="dot"></span>MWCC Reward platform Open</li>
        </ul>
      </div>
      <div class="item item7">
        <div>2024. 10</div>
        <ul>
          <li><span class="dot"></span>MWCC Staking platform Open</li>
          <li><span class="dot"></span>Live commerce platform Open</li>
        </ul>
      </div>
      <div class="item item8">
        <div>2025. 03</div>
        <ul>
          <li><span class="dot"></span>Global Open MWCC Commerce</li>
          <li><span class="dot"></span>MWCC Community Open</li>
          <li><span class="dot"></span>Set Cross platform</li>
        </ul>
      </div>
    </div>
  </div> -->
</template>
  
<script>
export default {
  name: "Part-8",
  props:{
    language: {
      type: String,
      default: 'english'
    }
  },
  computed:{
    txt(){
      if(this.language === 'chinese'){
        return  {
          t1: `Virtual Nation Metaworld 加密货币区块链平台，卖家和买家共同成长，创建新的 Metaverse 购物生态系统。`,
        }
      }
      return {
        t1: `Virtual Nation metaworld Cryptocurrency Blockchain platform where Seller and buyer grow together and create a new
        metaverse shopping ecosystem.`,
      }
    }
  }
};
</script>
  
<style lang="less" scoped>
.part8{
  video{
    width: 85%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: linear-gradient(180deg, rgba(229, 27, 32, 0.64) 0%, rgba(254, 245, 245, 0) 100%);
}

</style>
  