<template>
  <div class="part1 container-fluid" id="part1">
    
    <div class="part1-box">
      <header :class="{ 'part1-box-header': true, fixed: fixedstatus }">
        <img src="../assets/logo.png" class="part1-box-header-logo" />
        <div class="menu-box" @click="handlesmallmau">
          <img src="../assets/menu.png" />
          <div class="menu-container" v-show="smallmau">
            <div><a href="#part1">{{ txt.t1 }}</a></div>
            <div><a href="#part2">{{ txt.t2 }}</a></div>
            <!-- <div><a href="#part3">{{ txt.t3 }}</a></div> -->
            <div @click="jumpWhite"><a>{{ txt.t4 }}</a></div>
            <div class="line"></div>
            <div><a href="#part5">{{ txt.t5 }}</a></div>
            <div ><a  @click="entershop">{{ txt.t6 }}</a></div>
            <div class="line"></div>
            <div class="last">
              <span @click.stop="handlelan('english')">English</span>
              <span @click.stop="handlelan('chinese')">中 文</span>
            </div>
          </div>
        </div>
        <div class="part1-box-header-navs">
          <span><a href="#part1">{{ txt.t1 }}</a></span>
          <span><a href="#part2">{{ txt.t2 }}</a></span>
          <!-- <span><a href="#part3">{{ txt.t3 }}</a></span> -->
          <span @click="jumpWhite"><a>{{ txt.t4 }}</a></span>
          <span @click="jumpTwitter"><img src="../assets/twitter.png" /></span>
          <span @click="jumpX"><img src="../assets/telegram.png" /></span>
          <span class="part1-box-header-navs-bridge hoverbtn"><a href="#part5">{{ txt.t5 }}</a></span>
          <span @click="entershop" class="part1-box-header-navs-pug hoverbtn"><img src="../assets/pug.png" /><i></i><a href="#part6">{{ txt.t6
          }}</a></span>
          <span class="language" @click="handleclickLanguage"><img src="../assets/lan.png" />
            <div v-show="islan">
              <div @click.stop="handlelan('english')">English</div>
              <div @click.stop="handlelan('chinese')">中文</div>
            </div>
          </span>
        </div>
      </header>
      <img src="../assets/blogo.png" class="part1-box-middle-logo" />
      <div class="part1-box-middle-slog ">{{ txt.t7 }}</div>
      <p class="part1-box-middle-acticle">{{ txt.t8 }}</p>
      <div class="part1-box-acticle-img">
        <img src="../assets/image131.png" />
        <img src="../assets/image130.png" />
        <img src="../assets/image132.png" />
      </div>
      <span class="part1-box-acticle-btn" @click="entershop">{{ txt.t9 }}</span>
      <img src="../assets/Vector.png" class="part1-box-acticle-arrow" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Part-1",
  props: {
    language: {
      type: String,
      default: 'english'
    }
  },
  data() {
    return {
      islan: false,
      smallmau: false,
      fixedstatus: false,
    }
  },
  methods: {
    jumpTwitter() {
      window.open('https://twitter.com/Metaworldcc', '_blank')
    },
    jumpX() {
      window.open('https://t.me/MetaworldccOfficia', '_blank')
    },
    jumpWhite() {
      if(this.language === 'chinese') {

        window.open('http://mwcurrency.com/video/MWCC_Whitepaper_Chinese_version.pdf', '_blank')
      }else {
        window.open('http://mwcurrency.com/video/MWCC_Whitepaper_English_version.pdf', '_blank')

      }
    },
    entershop() {
      this.$router.push({
        path: '/shop',
        query:{
          language: this.language
        }
      })
    },
    handlelan(val) {
      console.log('val', val)
      this.islan = false
      this.smallmau = false
      this.$emit('changelan', val)
    },
    handleclickLanguage() {
      console.log('handleclickLanguage', this.islan)
      this.islan = true
    },
    handlesmallmau() {
      this.smallmau = !this.smallmau
    }
  },
  computed: {
    txt() {
      if (this.language === 'chinese') {
        return {
          t1: '关于我们',
          t2: '服务',
          t3: '路线图',
          t4: '白皮书',
          t5: '跨链桥',
          t6: '商店',
          t7: '第一个全球 B2E（Buy2Earn）平台',
          t8: 'Virtual Nation Metaworld 加密货币区块链平台，卖家和买家共同成长，创建新的 Metaverse 购物生态系统。',
          t9: '进入商城'
        }
      }
      return {
        t1: 'About us',
        t2: 'Services',
        t3: 'Roadmap',
        t4: 'Whitepaper',
        t5: 'Bridge',
        t6: 'Store',
        t7: '1st Global B2E (Buy2Earn) Platform',
        t8: 'Virtual Nation metaworld Cryptocurrency Blockchain platform where Seller and buyer grow together and create a new metaverse shopping ecosystem.',
        t9: 'Enter The Store'
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      console.log(document.body.scrollTop)
      if (document.body.scrollTop > 100) {
        this.fixedstatus = true
      } else {
        this.fixedstatus = false
      }

    }, true)
  }

};
</script>

<style lang="less" scoped>
.part1-box-header.fixed {
  position: fixed;
  /* 设置背景色为白色 */
  top: 0;
  transition: all 1s;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 999;
}
.part1-box-acticle-btn,.hoverbtn {
  transition: transform 0.3s ease;
}
.part1-box-acticle-btn:hover,.hoverbtn:hover {
  transform: scale(1.2);
}


/* 当滚动到指定位置时显示头部 */
@media (min-scroll-y: 20px) {
  .part1-box-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    /* 设置背景颜色为白色 */
    z-index: 999;
    /* 提高z-index属性值以优化布局 */
    transition: all 0.3s ease;
    /* 添加平滑过渡效果 */
  }

  .content {
    padding-top: 50px;
    /* 根据实际情况调整padding值 */
  }
}

a {
  color: rgba(77, 77, 77, 1);
  vertical-align: middle;
  text-decoration: none;
}

.part1-box-header-navs-pug>a {
  color: #fff;
}

.part1-box-acticle-img>img {
  transition: transform 0.3s ease;
}

.part1-box-acticle-img>img:hover {
  transform: scale(1.2);
}

.menu-box {
  position: relative;
}

.menu-container {
  width: 124px;
  height: 228px;
  border-radius: 10px;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 46px;
  background: rgba(217, 217, 217, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;

  >.line {
    height: 1;
    width: 100%;
    background: rgba(0, 0, 0, 1);
    margin-top: 15px;
    margin-bottom: 5px;
  }

  >.last {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    >span {
      display: inline-block;
    }
  }
}

.language {
  padding: 5.5px 5.5px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;

  >img {
    display: block;
    width: 21px;
    height: 21px;
    margin: 0 auto;
  }

  >div {
    position: absolute;
    top: 38px;
    left: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 83px;
    height: 61px;
    background: rgba(217, 217, 217, 1);
    border-radius: 12px;
    color: rgba(77, 77, 77, 1);
    font-size: 16px;
  }
}

/* PC样式 1440 以上 */
@media (min-width: 1440px) {
  .part1 {
    padding: 0;
    background: linear-gradient(125.92deg,
        #ffffff 29%,
        rgba(255, 106, 106, 0.415) 73.47%,
        rgba(255, 255, 255, 0) 105.03%);

    &-box {
      margin: 0 auto;
      padding: 40px 0 40px 0;
      height: 883px;
      width: 1440px;

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-acticle-arrow {
        margin-top: 20px;
        width: 13.33px;
        height: 18.02px;
      }

      &-acticle-btn {
        width: 186px;
        line-height: 70px;
        height: 70px;
        position: relative;
        z-index: 2;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
        background: rgba(229, 27, 32, 1);
        margin-top: -40px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 250, 250, 1);
        cursor: pointer;
      }

      &-acticle-img {
        display: block;
        position: relative;

        // margin-top: 20px;/
        img {
          display: inline-block;
          margin-top: -20px;
          width: 323px;
          height: 270px;
        }

        img:first-of-type {
          width: 306px;
          height: 234px;
          margin-top: 50px;
        }

        img:last-of-type {
          margin-top: 50px;
          width: 334px;
          height: 282px;
        }
      }

      &-middle-acticle {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        width: 733px;
        line-height: 30px;
        letter-spacing: 0.005em;
        text-align: center;
        color: rgba(103, 103, 103, 1);
        width: 733px;
        margin: 10px 0;
      }

      &-middle-slog {
        font-family: Poppins;
        font-size: 60px;
        font-weight: 700;
        line-height: 88px;
        letter-spacing: -0.01em;
        text-align: center;
        color: rgba(46, 46, 46, 1);
      }

      &-middle-logo {
        width: 386px;
        height: 117px;
        margin-top: 55px;
      }

      // 头部
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 135px;
        padding-right: 135px;
        font-size: 16px;
        width: 100%;

        .menu-box {
          display: none;
        }

        &-navs {
          color: rgba(77, 77, 77, 1);
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;

          &-pug {
            color: #fff;
            background: rgba(229, 27, 32, 1);
            padding: 3px 5px;
            width: 96px;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            >img {
              margin-right: 5px;
              width: 20px;
              height: 20px;
              position: relative;
            }

            >i {
              position: relative;
              margin-right: 8px;
              height: 27px;
            }

            >i::after {
              display: inline-block;
              position: absolute;
              right: 8px;
              content: "";
              border: 0 none;
              border-radius: 1px;
              height: 26px;
              width: 1.5px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #fff;
            }
          }

          &-bridge {
            width: 75px;
            line-height: 32px;
            background: #fff;
            border-radius: 6px;
            text-align: center;
          }

          >span {
            margin-left: 20px;
            display: inline-block;
            /* height: 32px; */
            vertical-align: center;
            cursor: pointer;

            >img {
              max-height: 28px;
              display: inline-block;
              vertical-align: center;
            }
          }
        }

        &-logo {
          display: inline-block;
          width: 120px;
          height: 54.34px;
        }
      }
    }
  }
}

/* Pc样式  1240 1439以下 以上 */
@media (max-width: 1439px) and (min-width: 1240px) {
  .part1 {
    padding: 0;
    background: linear-gradient(125.92deg,
        #ffffff 29%,
        rgba(255, 106, 106, 0.415) 73.47%,
        rgba(255, 255, 255, 0) 105.03%);

    &-box {
      margin: 0 auto;
      padding: 40px 0 40px 0;
      height: 883px;

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-acticle-arrow {
        margin-top: 20px;
        width: 13.33px;
        height: 18.02px;
      }

      &-acticle-btn {
        width: 186px;
        line-height: 70px;
        height: 70px;
        position: relative;
        z-index: 2;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
        background: rgba(229, 27, 32, 1);
        margin-top: -40px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 250, 250, 1);
        cursor: pointer;
      }

      &-acticle-img {
        display: block;
        position: relative;

        // margin-top: 20px;/
        img {
          display: inline-block;
          margin-top: -20px;
          width: 323px;
          height: 270px;
        }

        img:first-of-type {
          width: 306px;
          height: 234px;
          margin-top: 50px;
        }

        img:last-of-type {
          margin-top: 50px;
          width: 334px;
          height: 282px;
        }
      }

      &-middle-acticle {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        width: 733px;
        line-height: 30px;
        letter-spacing: 0.005em;
        text-align: center;
        color: rgba(103, 103, 103, 1);
        width: 733px;
        margin: 10px 0;
      }

      &-middle-slog {
        font-family: Poppins;
        font-size: 60px;
        font-weight: 700;
        line-height: 88px;
        letter-spacing: -0.01em;
        text-align: center;
        color: rgba(46, 46, 46, 1);
      }

      &-middle-logo {
        width: 386px;
        height: 117px;
        margin-top: 55px;
      }

      // 头部
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10%;
        font-size: 16px;
        width: 100%;

        .menu-box {
          display: none;
        }

        &-navs {
          color: rgba(77, 77, 77, 1);
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;

          &-pug {
            color: #fff;
            background: rgba(229, 27, 32, 1);
            padding: 3px 5px;
            width: 96px;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            >img {
              margin-right: 5px;
              width: 20px;
              height: 20px;
              position: relative;
            }

            >i {
              position: relative;
              margin-right: 8px;
              height: 27px;
            }

            >i::after {
              display: inline-block;
              position: absolute;
              right: 8px;
              content: "";
              border: 0 none;
              border-radius: 1px;
              height: 26px;
              width: 1.5px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #fff;
            }
          }

          &-bridge {
            width: 75px;
            line-height: 32px;
            background: #fff;
            border-radius: 6px;
            text-align: center;
          }

          >span {
            margin-left: 20px;
            display: inline-block;
            /* height: 32px; */
            vertical-align: center;
            cursor: pointer;

            >img {
              max-height: 28px;
              display: inline-block;
              vertical-align: center;
            }
          }
        }

        &-logo {
          display: inline-block;
          width: 120px;
          height: 54.34px;
        }
      }
    }
  }
}

/* Pc样式 960 1240以下 以上 */
@media (max-width: 1239px) and (min-width: 960px) {
  .part1 {
    padding: 0;
    background: linear-gradient(125.92deg,
        #ffffff 29%,
        rgba(255, 106, 106, 0.415) 73.47%,
        rgba(255, 255, 255, 0) 105.03%);

    &-box {
      margin: 0 auto;
      padding: 40px 0 40px 0;
      height: 883px;

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-acticle-arrow {
        margin-top: 20px;
        width: 13.33px;
        height: 18.02px;
      }

      &-acticle-btn {
        width: 186px;
        line-height: 70px;
        height: 70px;
        position: relative;
        z-index: 2;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
        background: rgba(229, 27, 32, 1);
        margin-top: -40px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 250, 250, 1);
        cursor: pointer;
      }

      &-acticle-img {
        display: block;
        position: relative;

        // margin-top: 20px;/
        img {
          display: inline-block;
          margin-top: -20px;
          width: 323px;
          height: 270px;
        }

        img:first-of-type {
          width: 306px;
          height: 234px;
          margin-top: 50px;
        }

        img:last-of-type {
          margin-top: 50px;
          width: 334px;
          height: 282px;
        }
      }

      &-middle-acticle {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        width: 733px;
        line-height: 30px;
        letter-spacing: 0.005em;
        text-align: center;
        color: rgba(103, 103, 103, 1);
        width: 733px;
        margin: 10px 0;
      }

      &-middle-slog {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 700;
        line-height: 88px;
        letter-spacing: -0.01em;
        text-align: center;
        color: rgba(46, 46, 46, 1);
      }

      &-middle-logo {
        width: 386px;
        height: 117px;
        margin-top: 55px;
      }

      // 头部
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10%;
        font-size: 16px;
        width: 100%;

        .menu-box {
          display: block;
          width: 44px;
          height: 44px;
          position: relative;

          >img {
            display: block;
            width: 44px;
            height: 44px;
          }
        }

        &-navs {
          display: none;
          color: rgba(77, 77, 77, 1);
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;

          &-pug {
            color: #fff;
            background: rgba(229, 27, 32, 1);
            padding: 3px 5px;
            width: 96px;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            >img {
              margin-right: 5px;
              width: 20px;
              height: 20px;
              position: relative;
            }

            >i {
              position: relative;
              margin-right: 8px;
              height: 27px;
            }

            >i::after {
              display: inline-block;
              position: absolute;
              right: 8px;
              content: "";
              border: 0 none;
              border-radius: 1px;
              height: 26px;
              width: 1.5px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #fff;
            }
          }

          &-bridge {
            width: 75px;
            line-height: 32px;
            background: #fff;
            border-radius: 6px;
            text-align: center;
          }

          >span {
            margin-left: 20px;
            display: inline-block;
            /* height: 32px; */
            vertical-align: center;
            cursor: pointer;

            >img {
              max-height: 28px;
              display: inline-block;
              vertical-align: center;
            }
          }
        }

        &-logo {
          display: inline-block;
          width: 120px;
          height: 54.34px;
        }
      }
    }
  }
}

/* Mobile样式 960 768以下 以上 */
@media (max-width: 959px) and (min-width: 768px) {
  .part1 {
    padding: 0;
    background: linear-gradient(125.92deg,
        #ffffff 29%,
        rgba(255, 106, 106, 0.415) 73.47%,
        rgba(255, 255, 255, 0) 105.03%);

    &-box {
      margin: 0 auto;
      padding: 30px 0 20px 0;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      &-acticle-arrow {
        display: block;
        margin: 0 auto;
        margin-top: 20px;
        width: 13.33px;
        height: 18.02px;
      }

      &-acticle-btn {
        display: block;
        margin: 0 auto;
        width: 186.32px;
        line-height: 70px;
        height: 70px;
        position: relative;
        z-index: 2;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
        background: rgba(229, 27, 32, 1);
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 250, 250, 1);
        cursor: pointer;
      }

      &-acticle-img {
        display: block;
        width: 100%;
        position: relative;
        margin-top: 10px;
        height: calc(85vw);

        >img {
          display: block;
        }

        img:first-of-type {
          position: absolute;
          width: 47.209%;
          left: 4.8837%;
          top: calc(31.83vw);
          z-index: 1;
        }

        img:nth-of-type(2) {
          position: absolute;
          width: calc(58.3726vw);
          left: 25.116279%;
          top: 0px;
          z-index: 3;
        }

        img:last-of-type {
          position: absolute;
          width: calc(47.44186vw);
          left: calc(46.27907vw);
          top: calc(38.604651vw);
          z-index: 2;
        }
      }

      &-middle-acticle {
        font-family: Poppins;
        display: block;
        margin: 0 auto;
        font-size: 15px;
        font-weight: 400;
        padding: 0 14px;
        max-width: 75%;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-align: center;
        color: rgba(103, 103, 103, 1);
        margin-top: 20px;
        margin-bottom: 30px;
      }

      &-middle-slog {
        display: block;
        margin: 0 auto;
        font-family: Poppins;
        font-size: 40px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.01em;
        padding: 0 20px;
        text-align: center;
        color: rgba(46, 46, 46, 1);
        margin-top: 20px;
      }

      &-middle-logo {
        width: 177px;
        height: 54px;
        margin: 0 auto;
        display: block;
        margin-top: 55px;

      }

      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 34px;
        padding-right: 15px;
        font-size: 16px;
        width: 100%;

        .menu-box {
          display: block;
          width: 44px;
          height: 44px;
          position: relative;

          >img {
            display: block;
            width: 44px;
            height: 44px;
          }
        }

        &-navs {
          display: none;
          color: rgba(77, 77, 77, 1);
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;

          &-pug {
            color: #fff;
            background: rgba(229, 27, 32, 1);
            padding: 3px 5px;
            width: 96px;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            >img {
              margin-right: 5px;
              width: 20px;
              height: 20px;
              position: relative;
            }

            >i {
              position: relative;
              margin-right: 8px;
              height: 27px;
            }

            >i::after {
              display: inline-block;
              position: absolute;
              right: 8px;
              content: "";
              border: 0 none;
              border-radius: 1px;
              height: 26px;
              width: 1.5px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #fff;
            }
          }

          &-bridge {
            width: 75px;
            line-height: 32px;
            background: #fff;
            border-radius: 6px;
            text-align: center;
          }

          >span {
            margin-left: 20px;
            display: inline-block;
            /* height: 32px; */
            vertical-align: center;
            cursor: pointer;

            >img {
              max-height: 28px;
              display: inline-block;
              vertical-align: center;
            }
          }
        }

        &-logo {
          display: inline-block;
          width: 91px;
          height: 41px;
        }
      }
    }
  }
}



/* Mobile样式 768以下 以上 */
@media (max-width: 767px) {
  .part1 {
    padding: 0;
    background: linear-gradient(125.92deg,
        #ffffff 29%,
        rgba(255, 106, 106, 0.415) 73.47%,
        rgba(255, 255, 255, 0) 105.03%);

    &-box {
      margin: 0 auto;
      padding: 30px 0 10px 0;
      height: 866px;
      width: 100%;

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-acticle-arrow {
        margin-top: 20px;
        width: 13.33px;
        height: 18.02px;
      }

      &-acticle-btn {
        width: 113.32px;
        line-height: 36px;
        height: 36px;
        position: relative;
        z-index: 2;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
        background: rgba(229, 27, 32, 1);
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(255, 250, 250, 1);
        cursor: pointer;
      }

      &-acticle-img {
        display: block;
        width: 100%;
        position: relative;
        margin-top: 10px;
        height: 390px;

        >img {
          display: block;
        }

        img:first-of-type {
          position: absolute;
          width: 47.209%;
          left: 4.8837%;
          top: 124px;
          z-index: 1;
        }

        img:nth-of-type(2) {
          position: absolute;
          width: 58.372%;
          left: 25.116279%;
          top: 0px;
          z-index: 3;
        }

        img:last-of-type {
          position: absolute;
          width: 47.44186%;
          left: 46.27907%;
          top: 166px;
          z-index: 2;
        }
      }

      &-middle-acticle {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 400;
        padding: 0 14px;
        max-width: 322px;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-align: center;
        color: rgba(103, 103, 103, 1);
        width: 733px;
        margin: 15px 0;
      }

      &-middle-slog {
        font-family: Poppins;
        font-size: 30px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.01em;
        padding: 0 20px;
        max-width: 430px;
        text-align: center;
        color: rgba(46, 46, 46, 1);
      }

      &-middle-logo {
        width: 177px;
        height: 54px;
        margin-top: 55px;
      }

      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 34px;
        padding-right: 15px;
        font-size: 16px;
        width: 100%;

        .menu-box {
          display: block;
          width: 44px;
          height: 44px;
          position: relative;

          >img {
            display: block;
            width: 44px;
            height: 44px;
          }
        }

        &-navs {
          display: none;
          color: rgba(77, 77, 77, 1);
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;

          &-pug {
            color: #fff;
            background: rgba(229, 27, 32, 1);
            padding: 3px 5px;
            width: 96px;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            >img {
              margin-right: 5px;
              width: 20px;
              height: 20px;
              position: relative;
            }

            >i {
              position: relative;
              margin-right: 8px;
              height: 27px;
            }

            >i::after {
              display: inline-block;
              position: absolute;
              right: 8px;
              content: "";
              border: 0 none;
              border-radius: 1px;
              height: 26px;
              width: 1.5px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #fff;
            }
          }

          &-bridge {
            width: 75px;
            line-height: 32px;
            background: #fff;
            border-radius: 6px;
            text-align: center;
          }

          >span {
            margin-left: 20px;
            display: inline-block;
            /* height: 32px; */
            vertical-align: center;
            cursor: pointer;

            >img {
              max-height: 28px;
              display: inline-block;
              vertical-align: center;
            }
          }
        }

        &-logo {
          display: inline-block;
          width: 91px;
          height: 41px;
        }
      }
    }
  }
}
</style>
