<!--
 * @Descripttion: 
 * @version: 
 * @Author: IDHorse
 * @Date: 2024-01-26 08:40:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-26 08:53:31
-->
<template>
  <div id="app" ref="app">
    <router-view></router-view>
  </div>
</template>

<script>



export default {
    name: "App",
};
</script>

<style>
html,body{
  font-size: 10px;
}
#app {
  height: 100%;
  width: 100%;
}
</style>
